import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/navigatorregistry/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/navigatorregistry/find/${id}`);
  },
  changeStatus: function (data) {
    return axios.put(`/api/navigatorregistry/changestatus/${data.id}`, data);
  },
  exportRegistry: function (request) {
    return Utils.downloadFile('/api/navigatorregistry/export', '', request);
  },
  getModerationStatuses: function () {
    return axios.get('/api/navigatorregistry/moderationstatuses', {});
  },
};
