<template>
  <div class="container-page" v-if="Object.keys(card).length">
    <div>
      <p class="title-inner">
        Запись реестра
        {{ card.registryType === 0 ? 'сообщений о некорректной информации' : 'предложений по оптимизации процедуры' }}
      </p>
    </div>
    <div class="table-container mb-16 js-table-arrow">
      <table class="stockTable">
        <tbody>
          <tr>
            <td>Пользователь</td>
            <td>{{ card.createdBy?.fullName }}</td>
          </tr>
          <tr>
            <td>Адрес эл. почты</td>
            <td>{{ card.createdBy?.user?.email }}</td>
          </tr>
          <tr>
            <td>Статус</td>
            <td>{{ card.moderationStatus.title }}</td>
          </tr>
          <tr>
            <td>Дата подачи</td>
            <td>{{ $momentFormat(card.created, 'DD.MM.YYYY HH:mm') }}</td>
          </tr>
          <tr>
            <td>Вид деятельности</td>
            <td>{{ card.tradingOperation.name }}</td>
          </tr>
          <tr>
            <td>Товарная позиция</td>
            <td>{{ card.tnved?.name }}</td>
          </tr>
          <tr>
            <td>Процедура</td>
            <td>{{ card.procedure.name }}</td>
          </tr>
          <tr>
            <td>Шаг процедуры</td>
            <td>{{ card.step.name }}</td>
          </tr>
          <template v-if="card.registryType === 0">
            <tr>
              <td>Название поля, в котором содержится ошибка</td>
              <td>{{ card.attributeName }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td>Страна</td>
              <td>{{ card.country.name }}</td>
            </tr>
          </template>
          <tr>
            <td>Текст</td>
            <td>{{ card.body }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="form-group">
      <div class="form-label">
        Комментарий к изменению статуса
        <small v-if="card.modified"
          >(Добавлен: {{ card.modifiedBy?.fullName }} {{ $momentFormat(card.modified, 'DD.MM.YYYY HH:mm') }})</small
        >
      </div>
      <div>
        <textarea v-model="card.statusComment" rows="3" class="form-control"></textarea>
      </div>
    </div>
    <div class="form-group">
      <label class="form-label">Документ</label>
      <FileUpload
        width="100%"
        :entity="card"
        @deleteFile="removeFile"
        prop-name="registryEntryFile"
        @uploaded="fileUploaded"
      />
    </div>
    <div class="card-info__row">
      <div>
        <ButtonStock
          class="mr-8"
          title="На рассмотрение"
          v-on:click="changeStatus(2)"
          :disabled="card.moderationStatusId !== 1"
        />
        <ButtonStock
          class="mr-8"
          title="Принять"
          v-on:click="changeStatus(3)"
          :disabled="card.moderationStatusId !== 2"
        />
        <ButtonStock
          v-if="card.registryType === 0"
          class="mr-8"
          title="Отклонить"
          v-on:click="changeStatus(4)"
          :disabled="card.moderationStatusId !== 2"
        />
      </div>
      <ButtonStock btnType="secondary" title="В реестр" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import FileUpload from '@/common/components/FileUpload';
  import Constants from '@/common/constants';

  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../api/registry';

  export default {
    name: 'RegistryCard',
    components: { ButtonStock, FileUpload },
    data() {
      return {
        id: this.$route.params.id,
        card: {},
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      changeStatus(statusId) {
        API.changeStatus({
          id: this.card.id,
          statusId: statusId,
          comment: this.card.statusComment,
          file: this.card.registryEntryFile,
        })
          .then(() => {
            Constants.alert.fire('Изменение статуса', 'Статус записи успешно изменен.', 'success');
            this.loadCard();
          })
          .catch((error) => {
            Constants.alert.fire('Изменение статуса', Constants.commonError, 'error');
            console.error(error?.response?.data);
          });
      },
      close() {
        this.$router.push({ name: 'NavigatorRegistry' });
      },
      fileUploaded(e) {
        this.card.registryEntryFile = e.data;
        this.card.registryEntryFileId = e.data ? e.data.id : null;
      },
      removeFile() {
        this.card.registryEntryFile = null;
        this.card.registryEntryFileId = null;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .form-label {
    font-weight: 400 !important;
    font-size: 14px;
    color: #231f20;
    line-height: 160%;
  }
</style>
